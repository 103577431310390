<template>
    <data-table
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="dataset"
        :buttons="buttons"
        :lang="$t('common.currentLang')"
        :paging="false"
        @download="onDownload"
    />
</template>

<script>
import filesize from 'filesize'

export default {
    name: 'FilesDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        fileTypes () {
            return {
                idCard: this.$t('filesDataTable.idCard'),
                secondIdCard: this.$t('filesDataTable.secondIdCard'),
                bankAccountStatement: this.$t('filesDataTable.bankAccountStatement'),
                other: this.$t('filesDataTable.other')
            }
        },
        header () {
            return [
                {
                    text: this.$t('filesDataTable.filename'),
                    data: 'filename',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('filesDataTable.type'),
                    data: 'type',
                    format (value) {
                        return (this.fileTypes[value]) ? this.fileTypes[value] : value
                    },
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('filesDataTable.size'),
                    data: 'size',
                    format: filesize,
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'download',
                    variant: 'primary',
                    text: this.$t('common.download')
                }
            ]
        }
    },
    methods: {
        onDownload (row) {
            this.$emit('download', row)
        }
    }
}
</script>
