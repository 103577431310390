<template>
    <b-form @submit.prevent="onSubmit">
        <div class="row">
            <div class="col-12 col-lg-6">
                <form-input
                    :label="$t('userForm.firstName')"
                    :id="'userForm.firstName'"
                    v-model="firstName"
                    :validation="$v.firstName"
                />
            </div>
            <div class="col-12 col-lg-6">
                <form-input
                    :label="$t('userForm.lastName')"
                    :id="'userForm.lastName'"
                    v-model="lastName"
                    :validation="$v.lastName"
                />
            </div>
        </div>
        <form-input
            :label="$t('userForm.email')"
            :id="'userForm.email'"
            v-model="email"
            :validation="$v.email"
        />
        <form-input
            :formatter="formatPhoneNumber"
            :label="$t('userForm.phoneNumber')"
            :id="'userForm.phoneNumber'"
            v-model="phoneNumber"
            :validation="$v.phoneNumber"
            :validation-messages="{ phoneNumber: $t('userForm.phoneNumberInvalid') }"
        />

        <b-form-group
            v-if="id"
        >
            <b-form-checkbox
                v-model="changePassword"
            >
                {{$t('userForm.changePassword')}}
            </b-form-checkbox>
        </b-form-group>

        <div v-if="changePassword || !id">
            <form-input
                :label="$t('userForm.password')"
                :id="'userForm.password'"
                type="password"
                v-model.trim="password"
                :validation="$v.password"
                :formatter="(value) => `${value}`.trim()"
                autocomplete="false"
            />

            <form-input
                :label="$t('userForm.confirmPassword')"
                :id="'userForm.confirmPassword'"
                type="password"
                v-model.trim="confirmPassword"
                :validation="$v.confirmPassword"
                :validation-messages="{ same: $t('userForm.passwordNotSame') }"
                :formatter="(value) => `${value}`.trim()"
                autocomplete="false"
            />
        </div>

        <form-input-select
            :multi="true"
            :label="$t('userForm.roles')"
            :id="'userForm.roles'"
            v-model="roles"
            :validation="$v.roles"
            :options="roleOptions"
        />
        <form-input-select
            :label="$t('userForm.izdocsCompanyId')"
            :id="'userForm.izdocsCompanyId'"
            v-model="izdocsCompanyId"
            :validation="$v.izdocsCompanyId"
            :options="izdocsCompanyIdOptions"
        />

        <div class="mt-4">
            <b-btn
                variant="primary"
                type="submit"
                class="mr-3"
            >
                <span class="iconify mr-2" data-icon="mdi:content-save"></span>{{$t('userForm.submit')}}
            </b-btn>
            <b-btn
                variant="white"
                @click.prevent="onCancel"
            >
                <span class="iconify mr-2" data-icon="mdi:close"></span>{{$t('userForm.cancel')}}
            </b-btn>
        </div>
    </b-form>
</template>

<script>
import { required, email, numeric, helpers, requiredIf, minLength } from 'vuelidate/lib/validators'
import { parsePhoneNumberFromString, parseIncompletePhoneNumber } from 'libphonenumber-js/mobile'

export default {
    name: 'UserForm',
    props: {
        user: {
            type: Object,
            required: false,
            default () {
                return {}
            }
        },
        brokers: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data () {
        return {
            /* eslint-disable-next-line vue/no-reserved-keys */
            id: ((this.user) ? (this.user._id) || null : null),
            email: null,
            firstName: null,
            lastName: null,
            phoneNumber: null,
            disabled: false,
            roles: [],
            izdocsCompanyId: null,
            errors: [],
            password: null,
            confirmPassword: null,
            changePassword: false,
            ...((this.user) ? JSON.parse(JSON.stringify(this.user)) : {})
        }
    },
    computed: {
        roleOptions () {
            return [
                {
                    value: 'user',
                    text: this.$t('roles.user')
                },
                {
                    value: 'izUserAdmin',
                    text: this.$t('roles.izUserAdmin')
                },
                {
                    value: 'izStorageAdmin',
                    text: this.$t('roles.izStorageAdmin')
                },
                {
                    value: 'izClientsAdmin',
                    text: this.$t('roles.izClientsAdmin')
                },
                {
                    value: 'izContractsAdmin',
                    text: this.$t('roles.izContractsAdmin')
                },
                {
                    value: 'admin',
                    text: this.$t('roles.admin')
                }
            ]
        },
        izdocsCompanyIdOptions () {
            return [
                {
                    value: null,
                    text: this.$t('common.none')
                },
                ...this.brokers
            ]
        }
    },
    watch: {
        user () {
            this.$nextTick(() => {
                this.fillUser(this.user)
            })
        }
    },
    methods: {
        fillUser (user) {
            if (user) {
                this.id = user._id
                this.email = user.email
                this.firstName = user.firstName
                this.lastName = user.lastName
                this.phoneNumber = user.phoneNumber
                this.disabled = user.disabled
                this.roles = user.roles
                this.izdocsCompanyId = user.izdocsCompanyId
                this.password = null
                this.confirmPassword = null
                this.changePassword = false
                this.errors = []
            } else {
                this.onCancel()
            }
        },
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('userForm.formError'))
                return this.$v.$touch()
            }
            this.$emit('submit', {
                _id: this.id || null,
                email: this.email,
                firstName: this.firstName,
                lastName: this.lastName,
                phoneNumber: this.phoneNumber,
                disabled: this.disabled,
                roles: this.roles,
                izdocsCompanyId: this.izdocsCompanyId || null,
                password: (this.changePassword || !this.id) ? this.password : null
            })
        },
        onCancel () {
            this.id = null
            this.email = null
            this.firstName = null
            this.lastName = null
            this.phoneNumber = null
            this.disabled = false
            this.roles = []
            this.izdocsCompanyId = null
            this.errors = []
            this.password = null
            this.confirmPassword = null
            this.changePassword = false
            this.$nextTick(() => {
                this.$v.$reset()
                this.$emit('cancel')
            })
        },
        formatPhoneNumber (number) {
            if (number) {
                return parseIncompletePhoneNumber(number)
            }
            return number
        }
    },
    validations () {
        const _this = this
        const validations = {
            email: {
                required,
                email
            },
            phoneNumber: {
                required,
                phoneNumber: (value) => {
                    if (!value) {
                        return false
                    }
                    const number = parsePhoneNumberFromString(`${value}`)
                    if (number && number.isValid()) {
                        return true
                    }
                    return false
                }
            },
            firstName: {
                required
            },
            lastName: {
                required
            },
            roles: {
                required
            },
            izdocsCompanyId: {
                numeric (value) {
                    return !helpers.req(value) || numeric(value)
                }
            }
        }

        if (_this.id) {
            validations.password = {
                required: requiredIf((model) => {
                    return model.changePassword
                }),
                minLength: (value) => {
                    return !_this.changePassword || minLength(10)(value)
                }
            }
            validations.confirmPassword = {
                required: requiredIf((model) => {
                    return model.changePassword
                }),
                same (value, model) {
                    return !_this.changePassword || (value === model.password)
                }
            }
        } else {
            validations.password = {
                required,
                minLength: minLength(10)
            }
            validations.confirmPassword = {
                required,
                same (value, model) {
                    return (value === model.password)
                }
            }
        }
        return validations
    }
}
</script>
