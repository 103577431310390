<template>
    <div>
        <h4>{{ $t('clientDetailSets.bankAccounts.title') }}</h4>
        <hr/>
        <div
            v-for="account in clientData.bankAccounts"
            :key="account.id"
        >
            <detail-item
                v-if="!account.foreign"
                :title="$t('clientDetailSets.bankAccounts.accountNumber')"
                :content="`${account.prefix}-${account.account}/${account.code}`"
            />
            <template v-else>
                <detail-item
                    :title="$t('clientDetailSets.bankAccounts.accountName')"
                    :content="account.accountName"
                />
                <detail-item
                    :title="$t('clientDetailSets.bankAccounts.iban')"
                    :content="account.iban"
                />
                <detail-item
                    :title="$t('clientDetailSets.bankAccounts.swift')"
                    :content="account.swift"
                />
                <detail-item
                    :title="$t('clientDetailSets.bankAccounts.bankNameAddress')"
                    :content="account.bankNameAddress"
                />
            </template>
            <detail-item
                :title="$t('clientDetailSets.bankAccounts.currency')"
                :content="account.currency"
            />
        </div>
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'PersonalSet',
    extends: DetailSetBase
}
</script>
