<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="dataset"
            :buttons="buttons"
            :lang="$t('common.currentLang')"
            :paging="true"
            @enable="onEnable"
            @disable="onDisable"
            @approve-registration="onApproveRegistration"
            @add-groups="onAddGroups"
        />
    </div>
</template>

<script>
export default {
    name: 'AgentsDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        broker: {
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            const header = [
                {
                    text: this.$t('agentsDataTable.name'),
                    data: 'name',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentsDataTable.email'),
                    data: 'email',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentsDataTable.phone'),
                    data: 'phone',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentsDataTable.referenceCode'),
                    data: 'referenceCode',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentsDataTable.capitolId'),
                    data: 'capitolId',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return value || this.$t('agentsDataTable.noCapitolId')
                    }
                },
                {
                    text: this.$t('agentsDataTable.disabled'),
                    data: 'disabled',
                    sortable: true,
                    filterable: true,
                    format: (value) => (value) ? this.$t('common.yes') : this.$t('common.no')
                }
            ]

            if (this.broker?.agentRegistrationNeedsApproval) {
                header.push({
                    text: this.$t('agentsDataTable.isApproved'),
                    data: 'isApproved',
                    sortable: true,
                    filterable: true,
                    format: (value) => (value) ? this.$t('common.yes') : this.$t('common.no')
                })
            }

            header.push({
                text: this.$t('agentsDataTable.agentGroups'),
                data: 'agentGroupNames',
                sortable: true,
                filterable: true,
                format (value) {
                    return [...(value ?? [])].join(', ')
                }
            })

            return header
        },
        buttons () {
            return [
                {
                    event: 'approve-registration',
                    variant: 'primary',
                    text: this.$t('agentsDataTable.approveRegistration'),
                    confirm: true,
                    visibleIf (data) {
                        return data.row.agentRegistrationNeedsApproval && !data.row.isApproved
                    }
                },
                {
                    event: 'add-groups',
                    variant: 'primary',
                    text: this.$t('agentsDataTable.groups')
                },
                {
                    event: 'enable',
                    variant: 'primary',
                    text: this.$t('agentsDataTable.enable'),
                    visibleIf (row) {
                        return row.row.disabled
                    }
                },
                {
                    event: 'disable',
                    variant: 'danger',
                    text: this.$t('agentsDataTable.disable'),
                    visibleIf (row) {
                        return !row.row.disabled
                    }
                }
            ]
        }
    },
    methods: {
        onEnable (row) {
            this.$emit('enable', row)
        },
        onDisable (row) {
            this.$emit('disable', row)
        },
        onApproveRegistration (row) {
            this.$emit('approve-registration', row)
        },
        onAddGroups (row) {
            this.$emit('add-groups', row)
        }
    }
}
</script>
