<template>
    <div class="bg-white shadow-sm cyrrus-rounded p-4">
        <h3>{{$t('othersCard.title')}}</h3>
        <loader-overlay :loading="loading">
            <b-form @submit.prevent="onSubmit">
                <form-input-checkbox
                    :label="$t('othersCard.allowAPICalls')"
                    v-model="allowAPICalls"
                />
                <div class="mt-4">
                    <b-btn
                        variant="primary"
                        type="submit"
                        size="sm"
                        class="mr-3"
                    >
                        <span class="iconify mr-2" data-icon="mdi:content-save"></span>{{$t('othersCard.submit')}}
                    </b-btn>
                </div>
            </b-form>
        </loader-overlay>
    </div>
</template>
<script>
export default {
    name: 'OthersCard',
    data () {
        return {
            allowAPICalls: false,
            loading: true
        }
    },
    computed: {
        user () {
            return this.$user
        }
    },
    mounted () {
        this.allowAPICalls = this.user.allowAPICalls
        this.loading = false
    },
    methods: {
        onSubmit () {
            this.loading = true
            this.$api.profile.updateProfile({
                firstName: `${this.user.firstName}`,
                lastName: `${this.user.lastName}`,
                email: `${this.user.email}`,
                phoneNumber: `${this.user.phoneNumber}`,
                allowAPICalls: this.allowAPICalls
            })
                .then(() => {
                    this.$notify.success(this.$t('othersCard.formSuccess'))
                })
                .catch((error) => {
                    this.$notify.error(this.$t('othersCard.formSubmitError'))
                    console.error(error)
                })
                .finally(() => {
                    this.$nextTick(() => {
                        this.$user.reload()
                        this.loading = false
                    })
                })
        }
    }
}
</script>
