<template>
<section class="section-narrow">
    <div class="section-content">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-10">
                    <h1>{{$t('index.heading')}}</h1>

                    <p>{{$t('index.subtitle')}}</p>

                    <div class="row">
                        <div class="col-12 col-md-8">
                            <login-form
                                v-if="!isLoggedIn"
                                @logged-in="onLoggedIn"
                            />
                            <mfa-form
                                v-else
                                @logout="onLoggedOut"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import LoginForm from './Components/LoginForm.vue'
import MfaForm from './Components/MfaForm.vue'

export default {
    name: 'IndexPage',
    components: {
        LoginForm,
        MfaForm
    },
    data () {
        return {
            isLoggedIn: false
        }
    },
    methods: {
        onLoggedIn () {
            this.isLoggedIn = true
        },
        onLoggedOut () {
            this.isLoggedIn = false
        }
    }
}
</script>
