<template>
    <div class="bg-white shadow-sm cyrrus-rounded py-4">
        <h3 class="px-4">{{$t('changePassword.title')}}</h3>
        <loader-overlay :loading="loading">
            <b-form
                class="px-4"
                @submit.prevent="onSubmit"
            >
                <form-input
                    :label="$t('changePassword.password')"
                    :id="'changePassword.changePassword'"
                    type="password"
                    v-model.trim="password"
                    :validation="$v.password"
                    :formatter="(value) => `${value}`.trim()"
                />

                <form-input
                    :label="$t('changePassword.newPassword')"
                    :id="'changePassword.newPassword'"
                    type="password"
                    v-model.trim="newPassword"
                    :validation="$v.newPassword"
                    :formatter="(value) => `${value}`.trim()"
                />

                <form-input
                    :label="$t('changePassword.confirmPassword')"
                    :id="'changePassword.confirmPassword'"
                    type="password"
                    v-model.trim="confirmPassword"
                    :validation="$v.confirmPassword"
                    :validation-messages="{ same: $t('changePassword.passwordNotSame') }"
                    :formatter="(value) => `${value}`.trim()"
                />

                <div class="mt-4">
                    <b-btn
                        variant="primary"
                        type="submit"
                        size="sm"
                        class="mr-3"
                    >
                        <span class="iconify mr-2" data-icon="mdi:content-save"></span>{{$t('changePassword.submit')}}
                    </b-btn>
                </div>
            </b-form>
        </loader-overlay>
    </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    name: 'PasswordChanger',
    data () {
        return {
            password: null,
            newPassword: null,
            confirmPassword: null,
            loading: false
        }
    },
    methods: {
        onSubmit () {
            if (this.$v.$invalid) {
                this.$notify.error(this.$t('changePassword.formError'))
                return this.$v.$touch()
            }
            this.loading = true
            this.$api.profile.changePassword(this.password, this.newPassword)
                .then((response) => {
                    this.code = null
                    this.$notify.success(this.$t('changePassword.formSuccess'))
                    this.password = null
                    this.newPassword = null
                    this.confirmPassword = null
                    this.$v.$reset()
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('changePassword.passwordInvalid'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.loading = false
                    })
                })
        }
    },
    validations: {
        password: {
            required
        },
        newPassword: {
            required,
            minLength: minLength(10)
        },
        confirmPassword: {
            required,
            same (value, model) {
                return value === model.newPassword
            }
        }
    }
}
</script>
