<template>
    <div>
        <div class="container">
            <h1>{{$t('storage.heading')}}</h1>
            <p>{{$t('storage.subtitle')}}</p>
        </div>
        <div class="container-fluid">
            <div class="row mb-4" v-if="showUploader">
                <div class="col-12 col-md-6">
                    <div class="p-4 bg-white shadow rounded-sm">
                        <h5>{{$t('storage.uploaderLabel')}}</h5>
                        <uploader-box
                            :uploader-name="'brokerUpload'"
                            :multi="true"
                            :url="uploadUrl"
                            @uploaded="onUploaded"
                        />
                        <div class="mt-4">
                            <b-btn
                                variant="light"
                                size="sm"
                                @click.prevent="showUploader = false"
                            >
                                <span class="iconify" data-icon="mdi:close"></span> {{$t('storage.close')}}
                            </b-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-4 bg-white shadow rounded-sm">
                <div class="mb-4">
                    <b-btn
                        variant="primary"
                        size="sm"
                        @click.prevent="showUploader = true"
                    >
                        <span class="iconify" data-icon="mdi:upload"></span> {{$t('storage.openUploader')}}
                    </b-btn>
                </div>
                <directory-data-table
                    :dataset="files"
                    :loading="filesLoading"
                    :top-folder="topFolder"
                    @download="onDownload"
                    @delete="onDelete"
                />
            </div>
        </div>
    </div>
</template>

<script>
import UploaderBox from './Components/UploaderBox.vue'
import DirectoryDataTable from './Components/DirectoryDataTable.vue'

export default {
    name: 'Storage',
    components: {
        UploaderBox,
        DirectoryDataTable
    },
    data () {
        return {
            files: [],
            filesLoading: true,
            topFolder: true,
            showUploader: false
        }
    },
    computed: {
        uploadUrl () {
            return this.$api.fileStorage.getBrokerUploadUrl(this.$user.izdocsCompanyId)
        }
    },
    mounted () {
        this.loadFiles()
    },
    methods: {
        onUploaded () {
            this.$notify.success(this.$t('storage.fileUploaded'))
            this.loadFiles()
        },
        onDelete (row) {
            this.$api.fileStorage.deleteBrokerFile(this.$user.izdocsCompanyId, row.prefix + '/' + row.filename)
                .then(() => {
                    this.$notify.success(this.$t('storage.deleteSuccess'))
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('storage.deleteError'))
                }).finally(() => {
                    this.loadFiles()
                })
        },
        onDownload (row) {
            this.$api.fileStorage.downloadBrokerFile(this.$user.izdocsCompanyId, row.prefix + '/' + row.filename)
                .catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('storage.downloadError'))
                })
        },
        loadFiles () {
            this.filesLoading = true
            this.$api.fileStorage.getBrokerFiles(this.$user.izdocsCompanyId)
                .then((response) => {
                    this.files = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error('storage.filesLoadError')
                }).finally(() => {
                    this.$nextTick(() => {
                        this.filesLoading = false
                    })
                })
        }
    }
}
</script>
