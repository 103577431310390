<template>
    <div>
        <slot name="header">
            <h5 class="mb-3 mt-4">{{$t('investCategoriesList.investmentCategories')}}</h5>
            <p v-if="!noHeader">
                <strong>{{$t('investCategoriesList.client')}}: {{clientInstance.fullName}}</strong>
            </p>
        </slot>
        <div class="alert alert-danger" v-if="categories.length === 0">
            {{$t('investCategoriesList.noActiveCategories')}}
        </div>
        <div class="list-group">
            <div
                v-for="item in categories"
                :key="item.uid"
                class="list-group-item"
            >
                <p class="mb-1">
                    <strong>
                        {{$t(`categories.${item.name}`)}}
                    </strong>
                </p>
                <small class="text-muted">
                    {{$t('investCategoriesList.added')}}: {{(new Date(item.added)).toLocaleString()}}, {{$t('investCategoriesList.uid')}}: {{item.uid}}
                </small>
            </div>
        </div>
    </div>
</template>

<script>
import { ClientData } from '@cyrrus/clientdatastructure'

export default {
    name: 'InvestCategoriesList',
    props: {
        client: {
            type: Object,
            required: true
        },
        noHeader: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        clientInstance () {
            return (this.client instanceof ClientData) ? this.client : new ClientData(this.client)
        },
        categories () {
            return (this.clientInstance.client.investmentCategories || [])
        }
    }
}
</script>
