<template>
    <div>
        <h4 v-if="showHeader">
            {{ $t('clientDetailSets.idCard.title') }}
        </h4>
        <hr v-if="showHeader" />
        <p
            v-if="clientData.idCard.scanNotAllowed"
            class="text-danger"
        >
            <strong>{{ $t('clientDetailSets.idCard.scanNotAllowed') }}</strong>
        </p>
        <p
            v-if="!isValid"
            class="alert alert-danger"
        >
            {{ $t('clientDetailSets.idCard.invalidId') }}
        </p>

        <p
            v-if="isValid && isNearValidityEnd"
            class="alert alert-warning"
        >
            {{ $t('clientDetailSets.idCard.nearValidityEnd', [daysLeft]) }}
        </p>

        <detail-item
            :title="$t('clientDetailSets.idCard.type')"
            :content="clientData.idCard.type"
        />
        <detail-item
            :title="$t('clientDetailSets.idCard.number')"
            :content="clientData.idCard.number"
        />
        <detail-item
            :title="$t('clientDetailSets.idCard.issuedBy')"
            :content="clientData.idCard.issuedBy"
        />
        <detail-item
            :title="$t('clientDetailSets.idCard.countryName')"
            :content="clientData.idCard.countryName"
        />
        <detail-item
            :title="$t('clientDetailSets.idCard.validity')"
            :content="clientData.idCard.validity.toLocaleDateString()"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'IdCardSet',
    extends: DetailSetBase,
    props: {
        showHeader: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    computed: {
        daysLeft () {
            const dt1 = new Date()
            const dt2 = new Date(this.clientData.idCard.validity)
            return Math.floor(
                (
                    Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
                    Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())
                ) /
                (1000 * 60 * 60 * 24)
            )
        },
        isValid () {
            return this.daysLeft >= 0
        },
        isNearValidityEnd () {
            return this.daysLeft <= 31
        }
    }
}
</script>
