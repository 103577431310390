<template>
    <section class="section-narrow">
        <div class="container">
            <h1>{{$t('emailChanged.heading')}}</h1>
            <p>{{$t('emailChanged.subtitle')}}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'EmailChanged'
}
</script>
