<template>
    <div :class="{ 'col-12 col-lg-4': horizontal }">
        <h4>{{ $t('clientDetailSets.personal.title') }}</h4>
        <hr/>
        <detail-item
            :title="$t('clientDetailSets.personal.type')"
            :content="clientData.typeName"
        />
        <detail-item
            :title="$t('clientDetailSets.personal.fullName')"
            :content="clientData.fullName"
        />
        <detail-item
            :title="$t('clientDetailSets.personal.taxDomicile')"
            :content="taxDomicile"
            v-if="!isCompany"
        />
        <detail-item
            :title="$t('clientDetailSets.personal.dateOfBirth')"
            :content="clientData.personal.dateOfBirth.toLocaleDateString()"
            v-if="!isCompany"
        />
        <detail-item
            :title="$t('clientDetailSets.personal.birthNumber')"
            :content="(clientData.personal.noBirthNumber) ? $t('clientDetailSets.personal.noBirtNumber') : clientData.personal.birthNumber"
            v-if="!isCompany"
        />
        <detail-item
            v-if="!clientData.personal.noBirthNumber && !isCompany"
            :title="$t('clientDetailSets.personal.birthNumberCountryName')"
            :content="clientData.personal.birthNumberCountryName"
        />
        <detail-item
            :title="$t('clientDetailSets.address.fullAddress')"
            :content="clientData.address.address.fullAddress"
        />
        <detail-item
            v-if="clientData.address.useMailingAddress"
            :title="$t('clientDetailSets.address.mailingAddress')"
            :content="clientData.address.mailingAddress.fullAddress"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'
import { countries } from '@cyrrus/locale-utils'

export default {
    name: 'PersonalSet',
    extends: DetailSetBase,
    computed: {
        taxDomicile () {
            return (this.clientData.personal.taxDomicile) ? countries[this.clientData.personal.taxDomicile] : null
        }
    }
}
</script>
