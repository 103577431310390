<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <div class="d-flex justify-content-between align-content-center align-items-center">
            <div>
                <div v-if="!loading" class="mt-2">
                    <b-badge
                        variant="primary"
                        v-if="session.status === 'export'"
                    >
                        {{$t('sessionDetail.status.export')}}
                    </b-badge>
                    <b-badge
                        variant="success"
                        v-if="session.status === 'exported'"
                    >
                        {{$t('sessionDetail.status.exported')}}
                    </b-badge>
                    <b-badge
                        variant="danger"
                        v-if="session.status === 'rejected'"
                    >
                        {{$t('sessionDetail.status.rejected')}}
                    </b-badge>
                    <b-badge
                        variant="danger"
                        v-if="session.status === 'discard'"
                    >
                        {{$t('sessionDetail.status.discard')}}
                    </b-badge>
                </div>
            </div>
        </div>
        <hr/>

        <div class="alert alert-info text-center" v-if="loading">
            {{$t('sessionDetail.loading')}}
        </div>

        <div v-else class="row">
            <div class="col-12">
                <div class="mb-4">
                    <b-btn
                        variant="secondary"
                        @click.prevent="onClose"
                        size="sm"
                        class="mr-2 mb-2"
                    >
                        <span class="iconify" data-icon="mdi:close"/> {{$t('sessionDetail.close')}}
                    </b-btn>

                    <b-btn
                        :variant="(archiveLoading) ? 'warning' : 'primary'"
                        @click.prevent="onDownloadArchive"
                        size="sm"
                        class="mr-2 mb-2"
                        :disabled="archiveLoading"
                    >
                        <span v-if="!archiveLoading">
                            <span class="iconify" data-icon="mdi:archive" />
                        </span>
                        <b-spinner v-else small></b-spinner>
                        {{$t((archiveLoading) ? 'sessionDetail.downloadArchiveLoading' : 'sessionDetail.downloadArchive')}}
                    </b-btn>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <client-detail-set
                    class="border border-gray p-4"
                    :client-data="clientData"
                />
            </div>

            <div class="col-12 col-md-8">
                <div v-if="session.status === 'rejected'" class="alert alert-warning mb-4">
                    <p><strong>{{$t('sessionDetail.rejectionNotice')}}</strong></p>
                    <p><strong>{{$t('sessionDetail.rejectionReason')}} </strong> {{session.rejectionMessage || $t('sessionDetail.noReason')}}</p>
                </div>
                <div class="border border-gray p-4">
                    <h4>{{$t('sessionDetail.broker')}}</h4>
                    <hr/>
                    <iz-broker-detail
                        :broker="broker"
                        :agent="agent"
                        :loading="brokerLoading"
                    />
                </div>

                <div class="border border-gray p-4 mt-4">
                    <h4>{{$t('sessionDetail.note')}}</h4>
                    <hr/>
                    <p v-if="!session.notes">{{$t('sessionDetail.noNote')}}</p>
                    <pre v-else>{{session.notes}}</pre>
                </div>

                <div class="border border-gray p-4 mt-4">
                    <h4>{{$t('sessionDetail.uploads')}}</h4>
                    <hr/>
                    <files-data-table
                        :dataset="files"
                        :loading="filesLoading"
                        @download="onDownload"
                    />
                </div>

                <div class="border border-gray p-4 mt-4">
                    <h4>{{$t('sessionDetail.documents')}}</h4>
                    <hr/>
                    <documents-data-table
                        :dataset="documents"
                        :loading="documentsLoading"
                        @pdf-download="onPdfDownload"
                        @pdf-detail="onPdfDetail"
                    />

                    <div class="embed-responsive embed-responsive-4by3 border border-gray" v-if="pdfUrl !== null">
                        <iframe class="embed-responsive-item mh-50vh" :src="pdfUrl" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientDetailSet from './ClientDetailSet.vue'
import { ClientData } from '@cyrrus/clientdatastructure'
import FilesDataTable from './FilesDataTable.vue'
import DocumentsDataTable from './DocumentsDataTable.vue'
import IzBrokerDetail from './IzBrokerDetail.vue'

export default {
    name: 'SessionDetail',
    components: {
        ClientDetailSet,
        FilesDataTable,
        DocumentsDataTable,
        IzBrokerDetail
    },
    props: {
        sessionId: {
            type: String,
            required: true
        },
        statuses: {
            type: Array,
            required: false,
            default () { return [] }
        }
    },
    data () {
        return {
            session: null,
            loading: true,
            filesLoading: true,
            files: [],
            documentsLoading: true,
            documents: [],
            pdfUrl: null,
            broker: null,
            agent: null,
            brokerLoading: true,
            archiveLoading: false
        }
    },
    computed: {
        clientData () {
            if (this.session === null) {
                return new ClientData()
            }
            return new ClientData(this.session.clientData)
        }
    },
    mounted () {
        this.onRefresh()
    },
    methods: {
        onRefresh () {
            this.loadSession()
            this.loadSessionFiles()
            this.loadSessionDocuments()
            this.loadBrokerWithAgent()
        },
        onClose () {
            this.$router.push({ name: 'ContractsAccepted' }).catch(() => {})
        },
        onPdfDetail (document) {
            this.pdfUrl = `${this.$api.dochub.client.defaults.baseURL}/${this.$api.dochub.ns}/brokers/${this.$user.izdocsCompanyId}/document-sessions/${this.sessionId}/documents/${document.id}/pdf`
        },
        onPdfDownload (document) {
            try {
                this.$api.dochub.downloadSessionDocument(this.$user.izdocsCompanyId, this.sessionId, document.id)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('sessionDetail.downloadError'))
            }
        },
        async onDownloadArchive () {
            if (this.archiveLoading) {
                return
            }
            this.archiveLoading = true
            try {
                await this.$api.dochub.downloadSessionArchive(this.$user.izdocsCompanyId, this.sessionId)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('sessionDetail.downloadError'))
            } finally {
                this.$nextTick(() => {
                    this.archiveLoading = false
                })
            }
        },
        onDownload (file) {
            try {
                this.$api.dochub.downloadSessionFile(this.$user.izdocsCompanyId, this.sessionId, file.uid)
            } catch (error) {
                console.error(error)
                this.$notify.error(this.$t('sessionDetail.downloadError'))
            }
        },
        loadSession () {
            this.loading = true
            this.$api.dochub.getDocumentSession(this.$user.izdocsCompanyId, this.sessionId)
                .then((response) => {
                    this.session = { ...response.data }
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('sessionDetail.sessionError'))
                    this.session = null
                    this.onClose()
                }).finally(() => {
                    this.$nextTick(() => {
                        this.loading = false
                    })
                })
        },
        loadBrokerWithAgent () {
            this.brokerLoading = true
            this.$api.dochub.getDocumentSessionBroker(this.$user.izdocsCompanyId, this.sessionId)
                .then((response) => {
                    this.agent = response.data.agent
                    this.broker = response.data.broker
                }).catch((error) => {
                    this.$notify.error(this.$t('sessionDetail.brokerAgentError'))
                    console.error(error)
                }).finally(() => {
                    this.$nextTick(() => {
                        this.brokerLoading = false
                    })
                })
        },
        loadSessionFiles () {
            this.filesLoading = true
            this.$api.dochub.getSessionFiles(this.$user.izdocsCompanyId, this.sessionId)
                .then((response) => {
                    this.files = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('sessionDetail.filesError'))
                    this.files = []
                }).finally(() => {
                    this.$nextTick(() => {
                        this.filesLoading = false
                    })
                })
        },
        loadSessionDocuments () {
            this.documentsLoading = true
            this.$api.dochub.getSessionDocuments(this.$user.izdocsCompanyId, this.sessionId)
                .then((response) => {
                    this.documents = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('sessionDetail.documentsError'))
                    this.documents = []
                }).finally(() => {
                    this.$nextTick(() => {
                        this.documentsLoading = false
                    })
                })
        }
    }
}
</script>

<style>
.mh-50vh {
    min-height: 50vh;
}
</style>
