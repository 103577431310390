<template>
    <div>
        <h4>{{ $t('clientDetailSets.system.title') }}</h4>
        <hr/>
        <detail-item
            :title="$t('clientDetailSets.system.dataOriginId')"
            :content="clientData.dataOriginId"
        />
        <detail-item
            :title="$t('clientDetailSets.system.capitolId')"
            :content="clientData.client.capitolId"
        />
        <detail-item
            :title="$t('clientDetailSets.system.crmId')"
            :content="clientData.client.crmId"
        />
        <detail-item
            :title="$t('clientDetailSets.system.dataOriginName')"
            :content="dataOriginName"
        />
        <detail-item
            :title="$t('clientDetailSets.system.typeName')"
            :content="clientData.typeName"
        />
        <detail-item
            :title="$t('clientDetailSets.system.residency')"
            :content="(clientData.client.residency) ? $t('common.yes') : $t('common.no')"
        />
        <detail-item
            :title="$t('clientDetailSets.system.externalBrokerCapitolId')"
            :content="(clientData.client.externalBrokerCapitolId) ? clientData.client.externalBrokerCapitolId : $t('common.no')"
        />
        <detail-item
            v-if="clientData.client.externalBrokerCapitolId"
            :title="$t('clientDetailSets.system.externalBrokerAgentCapitolId')"
            :content="(clientData.client.externalBrokerAgentCapitolId) ? clientData.client.externalBrokerAgentCapitolId : $t('common.no')"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'SystemSet',
    extends: DetailSetBase,
    computed: {
        dataOriginName () {
            if (this.clientData.dataOriginName === null || this.clientData.dataOriginName === undefined) {
                return this.$t('clientDetailSets.system.origin')
            }
            return this.clientData.dataOriginName
        }
    }
}
</script>
