<template>
    <div>
        <h4>{{ $t('clientDetailSets.contact.title') }}</h4>
        <hr/>
        <detail-item
            :title="$t('clientDetailSets.contact.email')"
            :content="clientData.contact.email"
        />
        <detail-item
            :title="$t('clientDetailSets.contact.phoneNumber')"
            :content="clientData.contact.phoneNumber"
        />
    </div>
</template>

<script>
import DetailSetBase from './DetailSetBase.vue'

export default {
    name: 'PersonalSet',
    extends: DetailSetBase,
    data () {
        return {
            displayPassword: false
        }
    }
}
</script>
