<template>
    <section class="section-narrow">
        <div class="container">
            <h1>{{$t('invalidEmailChange.heading')}}</h1>
            <p>{{$t('invalidEmailChange.subtitle')}}</p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InvalidEmailChange'
}
</script>
