<template>
    <div class="navbar-top-bar">
        <div class="container">
            <div class="navbar-top-bar-row">
                <div class="navbar-top-bar-logo">
                    <a href="/" class="site-brand" title="CYRRUS FX" target="_self">
                        <img src="/img/logo-cyrrus.svg" width="215" height="35" alt="CYRRUS, a.s." class="site-brand-logo"/>
                    </a>
                </div>

                <div class="navbar-top-bar-addons">
                    <div class="d-flex justify-content-end align-items-center align-content-center">
                        <div class="mr-3">
                            <span class="iconify text-cyrrus-orange" data-icon="mdi:phone"></span>
                            <a class="text-black text-decoration-none" :href="$t('about.phoneNumberWholeHref')" target="_self">
                                {{ $t('about.phoneNumberPrefix') }}
                                <strong>{{ $t('about.phoneNumber') }}</strong>
                            </a>
                        </div>
                        <b-dropdown
                            id="languageSwittcher"
                            :text="$t('common.languageSwitcher')"
                            variant="link"
                            toggle-class="text-decoration-none outline-0 shadow-none"
                            class="lang-switcher-link"
                        >
                            <template #button-content>
                                <img :src="$t('navigation.flag')" class="img img-fluid d-inline-block" />
                            </template>
                            <b-dropdown-item-button
                                v-for="lang in languageOptions"
                                :key="lang.value"
                                @click.prevent="onToggleLang(lang.value)"
                            >
                                <img :src="lang.flag" class="img d-inline-block mr-2" />
                                {{ lang.label }}
                            </b-dropdown-item-button>
                        </b-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopBar',
    computed: {
        languageOptions () {
            return [
                { value: 'cz', label: this.$t('common.czech'), flag: '/img/cz.svg' },
                { value: 'sk', label: this.$t('common.slovak'), flag: '/img/sk.svg' },
                { value: 'en', label: this.$t('common.english'), flag: '/img/gb.svg' },
                { value: 'hr', label: this.$t('common.croatian'), flag: '/img/hr.svg' }
            ]
        }
    },
    methods: {
        onToggleLang (language) {
            this.$store.commit('setLang', language)
            this.$i18n.locale = `${this.$store.state.lang}`
        }
    }
}
</script>
