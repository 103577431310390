<template>
    <data-table
        class="table-sm-font"
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="dataset"
        :buttons="buttons"
        :lang="$t('common.currentLang')"
        :responsive="'lg'"
        :paging="true"
        @add-agents="onAddAgents"
    />
</template>

<script>

export default {
    name: 'IzagentGroupsDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return [
                {
                    text: this.$t('agentGroupsDataTable.id'),
                    data: 'id',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentGroupsDataTable.name'),
                    data: 'name',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentGroupsDataTable.description'),
                    data: 'description',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('agentGroupsDataTable.agentsCount'),
                    data: 'agentsCount',
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'add-agents',
                    variant: 'primary',
                    text: this.$t('agentGroupsDataTable.addAgents')
                }
            ]
        }
    },
    methods: {
        onAddAgents (row) {
            this.$emit('add-agents', row)
        }
    }
}
</script>
