<template>
    <div class="container">
        <h1>{{$t('adminLanding.heading')}}</h1>
        <p>{{$t('adminLanding.subtitle')}}</p>
    </div>
</template>

<script>
export default {
    name: 'Index'
}
</script>
