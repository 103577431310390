<template>
    <p class="text-center">
        <b-spinner variant="primary" />
        <br/>
        {{ $t('common.loaderLoading') }}
    </p>
</template>

<script>
export default {
    name: 'Loader'
}
</script>
