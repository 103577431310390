<template>
    <data-table
        class="table-sm-font"
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="list"
        :buttons="buttons"
        :lang="this.$t('common.currentLang')"
        :responsive="'lg'"
        :paging="true"
        @detail="onDetail"
        @download="onDownload"
        :selectable-rows="true"
        :selectable-rows-checkboxes="true"
        :selectable-rows-track-by="'id'"
        :selectable-rows-class="'bg-warning text-black font-weight-bold'"
        v-model="model"
        :exportable="true"
        @export="onExport"
    />
</template>

<script>
import XLSX from 'xlsx'
import download from 'downloadjs'
import { CLIENT_TYPE_COMPANY } from '@cyrrus/clientdatastructure'

export default {
    name: 'IzDocumentsDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        archiveLoading: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    computed: {
        model: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('input', value)
            }
        },
        list () {
            return this.dataset.map((item) => {
                return {
                    id: item.id,
                    clientName: (item.clientData.client.type === CLIENT_TYPE_COMPANY) ? item.clientData.company.name : `${item.clientData.personal.lastName} ${item.clientData.personal.firstName}`,
                    legalId: (item.clientData.client.type === CLIENT_TYPE_COMPANY) ? item.clientData.company.ic : item.clientData.personal.birthNumber,
                    clientEmail: item.clientData.contact.email,
                    documentsCount: item.documents.length,
                    agentName: (item.agent) ? item.agent.name : '',
                    agentReferenceCode: (item.agent) ? item.agent.referenceCode : '',
                    lastChange: new Date(item.lastChange),
                    titlesForGrid: item.titlesForGrid
                }
            })
        },
        header () {
            return [
                {
                    text: this.$t('documentsDataTable.clientName'),
                    data: 'clientName',
                    sortable: true,
                    filterable: true,
                    cellClassnames: ['font-weight-bold']
                },
                {
                    text: this.$t('documentsDataTable.legalId'),
                    data: 'legalId',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('documentsDataTable.clientEmail'),
                    data: 'clientEmail',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('documentsDataTable.agentName'),
                    data: 'agentName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('documentsDataTable.agentReferenceCode'),
                    data: 'agentReferenceCode',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('documentsDataTable.lastChange'),
                    data: 'lastChange',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleDateString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: this.$t('documentsDataTable.titlesForGrid'),
                    data: 'titlesForGrid',
                    sortable: true,
                    filterable: true,
                    format (value) {
                        return value.join(', ')
                    }
                },
                {
                    text: this.$t('documentsDataTable.documentsCount'),
                    data: 'documentsCount',
                    sortable: false,
                    filterable: false
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'detail',
                    variant: 'primary',
                    text: this.$t('documentsDataTable.detail')
                },
                {
                    event: 'download',
                    variant: 'primary',
                    text: this.$t('documentsDataTable.downloadArchive')
                }
            ]
        }
    },
    methods: {
        onDetail (row) {
            this.$emit('detail', row)
        },
        onDownload (row) {
            if (this.archiveLoading) {
                return this.$notify.error(this.$t('documentsDataTable.inProgressError'))
            } else {
                this.$notify.success(this.$t('documentsDataTable.downloadStarted'))
                this.$emit('download', row)
            }
        },
        onExport (data) {
            const wk = XLSX.utils.book_new()
            const [jsonHeader, ...rows] = data
            const formatAsNumber = (value) => {
                // eslint-disable-next-line no-useless-escape
                return Number(`${value}`.replace(/[^0-9,\.]/g, '').replace(',', '.') ?? 0)
            }
            const sheet = XLSX.utils.json_to_sheet([jsonHeader, ...rows.map(row => {
                if (row.documentsCount !== undefined) {
                    row.documentsCount = formatAsNumber(row.documentsCount)
                }
                return row
            })], { skipHeader: true })
            XLSX.utils.book_append_sheet(wk, sheet, 'export')
            const output = XLSX.write(wk, { bookType: 'xlsx', bookSST: false, type: 'array' })
            download(
                new Blob([output], { type: 'application/octet-stream' }),
                `${this.$t('documentsDataTable.exportFilename')}.xlsx`,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        }
    }
}
</script>
