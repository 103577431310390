<template>
    <data-table
        :auto-update="false"
        :header="header"
        :loading="loading"
        :data="dataset"
        :buttons="buttons"
        :lang="$t('common.currentLang')"
        :paging="false"
        @pdf-download="onPdfDownload"
        @pdf-detail="onPdfDetail"
    />
</template>

<script>
export default {
    name: 'DocumentsDataTable',
    props: {
        dataset: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        header () {
            return [
                {
                    text: this.$t('documentsDataTable.title'),
                    data: 'title',
                    sortable: true,
                    filterable: true
                }
            ]
        },
        buttons () {
            return [
                {
                    event: 'pdf-detail',
                    variant: 'primary',
                    text: this.$t('common.detail')
                },
                {
                    event: 'pdf-download',
                    variant: 'primary',
                    text: this.$t('common.download')
                }
            ]
        }
    },
    methods: {
        onPdfDownload (row) {
            this.$emit('pdf-download', row)
        },
        onPdfDetail (row) {
            this.$emit('pdf-detail', row)
        }
    }
}
</script>
