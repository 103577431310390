<template>
    <div class="p-4 bg-white shadow rounded-sm">
        <slot></slot>
        <data-table
            class="table-sm-font"
            :auto-update="false"
            :header="header"
            :loading="loading"
            :data="dataset"
            :buttons="buttons"
            :lang="$t('common.currentLang')"
            :paging="true"
            :responsive="'lg'"
            :actions="false"
            :exportable="true"
            @export="onExport"
        />
    </div>
</template>

<script>
import XLSX from 'xlsx'
import download from 'downloadjs'

export default {
    name: 'LastOrdersDataTable',
    props: {
        dataset: {
            type: Array,
            required: false,
            default: () => []
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        datasetType: {
            type: String,
            required: false,
            default: null
        }
    },
    computed: {
        numberFormatter () {
            return new Intl.NumberFormat(this.$t('common.locale'))
        },
        header () {
            return [
                ...((this.datasetType === null) ? [{
                    text: this.$t('lastOrdersDataTable.capitolId'),
                    data: 'capitolId',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return value || this.$t('lastOrdersDataTable.noCapitolId')
                    }
                },
                {
                    text: this.$t('lastOrdersDataTable.name'),
                    data: 'name',
                    sortable: true,
                    filterable: true
                }] : []),
                ...((this.datasetType === 'agent' || this.dataset === null) ? [{
                    text: this.$t('lastOrdersDataTable.externalBrokerAgentName'),
                    data: 'externalBrokerAgentName',
                    sortable: true,
                    filterable: true
                }] : []),
                {
                    text: this.$t('lastOrdersDataTable.ISIN'),
                    data: 'ISIN',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('lastOrdersDataTable.stockName'),
                    data: 'stockName',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('lastOrdersDataTable.volume'),
                    data: 'volume',
                    sortable: true,
                    filterable: true,
                    format: this.formatCurrency,
                    sortFn (a, b) {
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: this.$t('lastOrdersDataTable.currencySymbol'),
                    data: 'currencySymbol',
                    sortable: true,
                    filterable: true
                },
                ...((this.datasetType === 'stock' || this.datasetType === 'agent') ? [{
                    text: this.$t('lastOrdersDataTable.numberOfClients'),
                    data: 'numberOfClients',
                    sortable: true,
                    filterable: true
                },
                {
                    text: this.$t('lastOrdersDataTable.numberOfOrders'),
                    data: 'numberOfOrders',
                    sortable: true,
                    filterable: true
                }] : []),
                ...((this.datasetType === 'stock') ? [{
                    text: this.$t('lastOrdersDataTable.numberOfAgents'),
                    data: 'numberOfAgents',
                    sortable: true,
                    filterable: true
                }] : []),
                ...((this.datasetType === null) ? [{
                    text: this.$t('lastOrdersDataTable.expectedVolume'),
                    data: 'expectedVolume',
                    sortable: true,
                    filterable: true,
                    format: this.formatCurrency
                },
                {
                    text: this.$t('lastOrdersDataTable.direction'),
                    data: 'direction',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? value === 'buy'
                            ? this.$t('common.buy')
                            : this.$t('common.sell')
                            : value
                    }
                },
                {
                    text: this.$t('lastOrdersDataTable.realizationDate'),
                    data: 'realizationDate',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleDateString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: this.$t('lastOrdersDataTable.lastOrderDate'),
                    data: 'lastOrderDate',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                },
                {
                    text: this.$t('lastOrdersDataTable.firstContractDate'),
                    data: 'firstContractDate',
                    sortable: true,
                    filterable: true,
                    format: (value) => {
                        return (value) ? new Date(value).toLocaleString() : value
                    },
                    sortFn (a, b) {
                        a = (new Date(a)).valueOf()
                        b = (new Date(b)).valueOf()
                        return ((a === b) ? 0 : ((a > b) ? 1 : -1))
                    }
                }] : [])
            ]
        },
        buttons () {
            return []
        }
    },
    methods: {
        formatCurrency (value) {
            if (typeof value === 'number') {
                return this.numberFormatter.format(Math.round(value * 100) / 100)
            }
            return 0
        },
        onExport (data) {
            const wk = XLSX.utils.book_new()
            const [jsonHeader, ...rows] = data
            const formatAsNumber = (value) => {
                // eslint-disable-next-line no-useless-escape
                return Number(`${value}`.replace(/[^0-9,\.]/g, '').replace(',', '.') ?? 0)
            }
            const sheet = XLSX.utils.json_to_sheet([jsonHeader, ...rows.map(row => {
                if (row.volume !== undefined) {
                    row.volume = formatAsNumber(row.volume)
                }
                if (row.numberOfClients !== undefined) {
                    row.numberOfClients = formatAsNumber(row.numberOfClients)
                }
                if (row.numberOfOrders !== undefined) {
                    row.numberOfOrders = formatAsNumber(row.numberOfOrders)
                }
                if (row.numberOfAgents !== undefined) {
                    row.numberOfAgents = formatAsNumber(row.numberOfAgents)
                }
                return row
            })], { skipHeader: true })
            XLSX.utils.book_append_sheet(wk, sheet, 'export')
            const output = XLSX.write(wk, { bookType: 'xlsx', bookSST: false, type: 'array' })
            download(
                new Blob([output], { type: 'application/octet-stream' }),
                `${this.$t('lastOrdersDataTable.excelName')}.xlsx`,
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            )
        }
    }
}
</script>
