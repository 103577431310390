<template>
    <div class="container-fluid">
        <h1>{{$t('property.heading')}}</h1>
        <p>{{$t('property.subtitle')}}</p>

        <property-data-table
            :dataset="clients"
            :loading="clientsLoading"
            @positions="onPositions"
            @lastOrders="onLastOrders"
            @agentPositions="onAgentPositions"
            @agentLastOrders="onAgentLastOrders"
        />
    </div>
</template>

<script>
import PropertyDataTable from './Components/PropertyDataTable.vue'

export default {
    name: 'Property',
    components: {
        PropertyDataTable
    },
    data () {
        return {
            clientsLoading: true,
            clients: []
        }
    },
    mounted () {
        this.loadClients()
    },
    methods: {
        loadClients () {
            this.clientsLoading = true
            this.$api.capitol.getBrokerClients(this.$user.izdocsCompanyId)
                .then((response) => {
                    this.clients = [...response.data]
                }).catch((error) => {
                    console.error(error)
                    this.$notify.error(this.$t('property.clientsLoadError'))
                }).finally(() => {
                    this.$nextTick(() => {
                        this.clientsLoading = false
                    })
                })
        },
        onPositions (row) {
            this.$router.push({ name: 'ClientsPositions', query: { client: row.capitolId } }).catch(() => {})
        },
        onLastOrders (row) {
            this.$router.push({ name: 'ClientsLastOrders', query: { client: row.capitolId } }).catch(() => {})
        },
        onAgentPositions (row) {
            this.$router.push({ name: 'ClientsPositions', query: { agent: row.externalBrokerAgentId } }).catch(() => {})
        },
        onAgentLastOrders (row) {
            this.$router.push({ name: 'ClientsLastOrders', query: { agent: row.externalBrokerAgentId } }).catch(() => {})
        }
    }
}
</script>
